import { ADPLOGGER_SDK_INIT, PLAYING_STATE_UPDATED } from '../actions.js';

const adpTypeConversion = {
  play: 'playing',
  timeupdate_5s: 'timeupdate',
  pause: 'pause',
  end: 'ended',
  seeked: 'pause',
};

export const adploggerSdkMiddleware = (store) => (next) => (action) => {
  const { adpLoggerInstance } = store.getState();

  if (action.type === ADPLOGGER_SDK_INIT) {
    // adpLoggerInstance is not available until ADPLOGGER_SDK_INIT action has ran in reducer
    action.payload.adpLoggerInstance.logPlayer(store.getState());
  }

  if (!adpLoggerInstance) return next(action);

  if (action.type === PLAYING_STATE_UPDATED) {
    const playState = action.payload;
    if (
      ['seeked', 'play', 'timeupdate_5s', 'pause', 'end'].includes(playState)
    ) {
      adpLoggerInstance.logVideoEvent({
        adpType: adpTypeConversion[playState],
      });
    }
  }

  return next(action);
};
