export const ADS_PAUSED = 'ADS_PAUSED';
export const CHAPTERS_LOADED = 'CHAPTERS_LOADED';
export const PLAYER_STATE_UPDATED = 'PLAYER_STATE_UPDATED';
export const PROPERTY_UPDATED = 'PROPERTY_UPDATED';
export const PLAYING_STATE_UPDATED = 'PLAYING_STATE_UPDATED';
export const SEEKED_TO = 'SEEKED_TO';
export const SKIP = 'SKIP';
export const REPLAY = 'REPLAY';
export const PLAYER_LOADED = 'PLAYER_LOADED';
export const OVP_CONFIG_LOADED = 'OVP_CONFIG_LOADED';
export const LOAD_PLAYER = 'LOAD_PLAYER';
export const PLAY = 'PLAY';
export const PAUSE = 'PAUSE';
export const UNMUTE = 'UNMUTE';
export const ADPLOGGER_SDK_INIT = 'ADPLOGGER_SDK_INIT';
export const ADPLOGGER_SDK_LOADED = 'ADPLOGGER_SDK_LOADED';

export const actions = {
  adsPaused: (isPaused) => ({ type: ADS_PAUSED, payload: isPaused }),
  loadPlayer: (element) => ({ type: LOAD_PLAYER, payload: element }),
  playerStateUpdated: (playerState) => ({
    type: PLAYER_STATE_UPDATED,
    payload: playerState,
  }),
  propertyUpdated: (key, value) => ({
    type: PROPERTY_UPDATED,
    payload: { key, value },
  }),
  playingStateUpdated: (playingState) => ({
    type: PLAYING_STATE_UPDATED,
    payload: playingState,
  }),
  seekedTo: (time) => ({ type: SEEKED_TO, payload: time }),
  // TODO: parse/validate ovpConfig
  ovpConfigLoaded: (config) => ({ type: OVP_CONFIG_LOADED, payload: config }),
  play: () => ({ type: PLAY }),
  replay: () => ({ type: REPLAY }),
  skip: () => ({ type: SKIP }),
  pause: () => ({ type: PAUSE }),
  unmute: () => ({ type: UNMUTE }),
};
