import { addMetaElement, addMetaEvent } from '@amedia/adplogger-sdk';
import {
  adpLoggerAdEventDto,
  adpLoggerEventDto,
  getLogConfig,
} from '../selectors.js';

/**
 * Factory that creates a state machine for tracking
 * duration between events.
 *
 * The rule is simple: the sum of all durations should
 * equal the total duration of playback.
 */
export function createDurationClock() {
  let time;

  function start() {
    time = Date.now();
    return 0;
  }

  function stop() {
    const duration = Date.now() - time;
    time = undefined;
    // if stop is called twice, return 0
    if (Number.isNaN(duration)) {
      return 0;
    }
    return duration;
  }

  function restart() {
    const duration = stop();
    start();
    return duration;
  }

  /**
   * @param {adpType: "playing" | "timeupdate" | "pause" | "ended" | "ad-resumed | "ad-completed" | "ad-skipped" | "ad-paused"}
   * @returns {number}
   */
  function getDuration(adpType) {
    if (['ad-started', 'playing', 'ad-resumed'].includes(adpType)) {
      return start();
    }

    if (
      ['pause', 'ended', 'ad-completed', 'ad-skipped', 'ad-paused'].includes(
        adpType
      )
    ) {
      return stop();
    }

    if (adpType === 'timeupdate') {
      return restart();
    }

    throw new Error(`Cannot detect timer for adpType: ${adpType}`);
  }

  return { getDuration };
}

export function createAdpLogger(element) {
  if (!element) {
    throw new Error('Element was not provided!');
  }

  const clock = createDurationClock();
  const adClock = createDurationClock();

  /**
   * @typedef {import('@amedia/adplogger-sdk').AdpEvent.PlaybackAdEvent.payload} Payload
   * @param {{ player: FlowplayerInstance, mediaId: string, playerId: string }}
   * @returns {void}
   */
  function logPlayer(state) {
    const payload = getLogConfig(state);
    addMetaElement(
      element,
      'Video',
      {
        ...payload,
        adpType: 'video',
      },
      {}
    );
  }

  /**
   * @typedef {import('@amedia/adplogger-sdk').MetaNode} MetaNode
   * @param {{ element: HTMLVideoElement, adpType: "playing" | "paused" }}
   * @returns {void}
   */
  function logVideoEvent({ adpType }) {
    const duration = clock.getDuration(adpType);

    const payload = adpLoggerEventDto({ adpType, duration });

    addMetaEvent(element, 'VideoEvent', payload);
  }

  /**
   * @typedef {import('@amedia/adplogger-sdk').MetaNode} MetaNode
   * @typedef {{vast_ad_id: string, ad_type: string, ad: {duration: number}}} EventDetail
   * @param {{ adpType: "ad-started" | "ad-skipped" | "ad-completed" | "ad-resumed" | "ad-paused", adType: "preroll", vastAdId: string, duration: number }}
   * @returns {void}
   */
  function logVideoAdEvent({ adpType, adType, vastAdId, adDuration }) {
    const duration = adClock.getDuration(adpType);

    const payload = adpLoggerAdEventDto({
      adpType,
      adType,
      vastAdId,
      duration,
      adDuration,
    });

    addMetaEvent(element, 'PlaybackAdEvent', payload);
  }

  return {
    logVideoEvent,
    logPlayer,
    logVideoAdEvent,
  };
}
