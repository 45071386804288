/* global globalThis */
import {
  ADS_PAUSED,
  CHAPTERS_LOADED,
  PLAYER_STATE_UPDATED,
  PROPERTY_UPDATED,
  SEEKED_TO,
  PLAYER_LOADED,
  ADPLOGGER_SDK_INIT,
  ADPLOGGER_SDK_LOADED,
  UNMUTE,
  OVP_CONFIG_LOADED,
} from './actions.js';

const DEFAULT_PLAYER_ID = 'c7fa8467-544d-45a6-818f-c28fa19be069';

export const initialState = () => {
  const urlParams = new URLSearchParams(globalThis.location?.search);
  const useBazaarLwhb =
    !!urlParams.get('bazaar_lwhb') ||
    ['https://www.mittjessheim.no', 'https://www.sb.no'].includes(
      globalThis.location?.origin
    );

  return {
    id: `brick-player:${new Date().toISOString()}`,
    isDebugLogging: !!urlParams.get('debug') === 'true',
    containerWidth: 0,
    features: {
      useBazaarLwhb,
    },
    pageUrl: globalThis.location.href,
    shouldShowUnmute: false,
    playerId: DEFAULT_PLAYER_ID,
  };
};

export function rootReducer(state = initialState(), action) {
  // thunks
  if (!action) {
    return state;
  }
  switch (action.type) {
    case ADS_PAUSED:
      return { ...state, adsIsPaused: action.payload };
    case CHAPTERS_LOADED:
      return { ...state, chapters: action.payload };
    case PLAYER_STATE_UPDATED:
      return {
        ...state,
        playerState: {
          ...state.playerState,
          isLoaded: action.payload['is-loaded'],
        },
      };
    case PROPERTY_UPDATED:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case SEEKED_TO:
      return { ...state, seek: action.payload };
    case PLAYER_LOADED:
      return {
        ...state,
        player: action.payload.player,
      };
    case ADPLOGGER_SDK_LOADED:
      return {
        ...state,
        adpLogger: action.payload.adpLogger,
      };
    case UNMUTE:
      return {
        ...state,
        shouldShowUnmute: false,
      };
    case OVP_CONFIG_LOADED:
      const autoplayRequested =
        Boolean(state.isAutoPlay) || Boolean(action.payload.autoplay);
      return {
        ...state,
        ovpConfig: action.payload,
        autoplayRequested,
        shouldShowUnmute: autoplayRequested && state.isMuted,
      };
    case ADPLOGGER_SDK_INIT:
      return {
        ...state,
        adpLoggerInstance: action.payload.adpLoggerInstance,
      };
    default:
      return state;
  }
}
